import React from 'react';
import * as styles from './record.module.scss';

import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";
import ChevronDownSVG from "../../icons/fontawesome/light/chevron-down.svg";

import Layout from "../page-components/layout/layout"
import { graphql } from "gatsby"
import IframeResizer from 'iframe-resizer-react'
import GoogleMapReact from 'google-map-react';
import OrtSimilar from "./ortSimilar"


const RecordTemplate = ( { data } ) => {

  const node = data.allWpRecord.nodes[0];

  return <Layout>

    <IframeResizer
      log
      allowFullScreen={true}
      src={ node.acf.matterport }
      style={
        { width: '1px', minWidth: '100%', height: '80vh' }
      }
    />


    <div className={"bg-primary-dark " + styles.title}>
      <div className="container my-0">
        <div className="row">
          <div className="col-12 text-white py-3">


            <div className="d-flex align-items-center justify-content-between c-pointer">
              <h1 className={"mb-1"}>{node.title}</h1>

              <div className={"d-flex"}>
              {node.acf.url &&
                <div className={"d-flex align-items-center " + styles.homepageButton}>

                  <ChevronRightSVG className={"svgFill me-1"} />

                  <a href={node.acf.url}
                     className={"text-white text-decoration-none"}
                     target={"_blank"}>
                    Homepage
                  </a>

                </div>
              }

              <div className={"d-flex align-items-center ms-3 " + styles.homepageButton}

                onClick={
                  () => {
                    window.scrollTo({  top: 500, behavior: 'smooth' } );
                  }
                }
              >

                <ChevronDownSVG className={"svgFill me-1"} />

                <a className={"text-white text-decoration-none"}>
                  Standort
                </a>

              </div>

              </div>

            </div>



            {/*{node.acf.kategorien &&*/}
            {/*<p>*/}
            {/*  {node.acf.kategorien.map(k => k.title).join(",")}*/}
            {/*</p>*/}
            {/*}*/}


            <div
              className={styles.description + " mt-2"}
              dangerouslySetInnerHTML={
                {
                  __html: node.acf.infotext?.replace(/href/g, "target='_blank' href")
                }
              }
            />

          </div>
        </div>
      </div>
    </div>



    <div className="container pt-4 pb-4">
      <div className="row">
        <div className="col-12">
          {node.acf.place?.latitude &&
          <div style={{ height: '270px', width: '100%', position: 'relative', top: '-4px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyCAWHzz1bsGomrv6H6Qj8FitTu7hvJ1Cvg" /* YOUR KEY HERE */ }}
              defaultCenter={
                {
                  lat: node.acf.place?.latitude,
                  lng: node.acf.place?.longitude
                }
              }
              defaultZoom={10}
              options={
                {
                  mapTypeControl: true
                }
              }
            >

              <div
                lat={node.acf.place?.latitude}
                lng={node.acf.place?.longitude}
                style={{
                color: '#b50a18',
                background: '#b50a18',
                padding: '10px',
                display: 'inline-flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%',
                transform: 'translate(-50%, -50%)'
              }}>

              </div>


            </GoogleMapReact>
          </div>
          }
        </div>

      </div>
    </div>

    <OrtSimilar />

  </Layout>
};

export default RecordTemplate;

export const query = graphql`query RecordDetail($id: String!) {
    allWpRecord(filter: {id: {eq: $id}}) {
      nodes {
        acf {
          url
          kategorien {
            ... on WpKategorie {
              id
              title
              slug
            }
          }
          teaser {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          matterport
          infotext
          place {
            latitude
            longitude
          }
          stadt {
            ... on WpStadt {
              id
              title
            }
          }
        }
        databaseId
        content
        id
        slug
        title
      }
    }
    
}`;
